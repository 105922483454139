import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { SendOtp } from "../../../../services/authentication";

import { useAuthentication } from "../../../../context/authentication-context";
import InputMSISDN from "../../../../components/InputMSISND.jsx";

import FormButton from "../../../../components/FormButton";
import Alert from "../../../../components/Alert";

export default function VerifyMsisdn({ moveToNextStep }) {
  const [msisdn, setMSISDN] = useState("");
  const [MSISDNError, setMSISDNError] = useState(null);
  const [isErrorVisible, setIsErrorVisible] = useState(false);

  const { setMobileNumber, setOtpConfig } = useAuthentication();

  let isValidMsisdn = /^01[0125]\d{8}$/.test(msisdn);

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: () => SendOtp(msisdn),
    onSuccess: onValidateMobileNumberSuccess,
    onError: () => setIsErrorVisible(true),
  });

  function onValidateMobileNumberSuccess(response) {
    setMobileNumber(msisdn);
    setOtpConfig({
      otpLength: response.otpLength || 0,
      otpTimer: response.otpTimer || "",
    });
    moveToNextStep();
  }

  function handleMsisdn(value) {
    setMSISDN(value);
    setMSISDNError(null);
    setIsErrorVisible(false);
  }

  function validateMSISDN() {
    if (!isValidMsisdn) {
      setMSISDNError("رقم الموبايل الذي أدخلته غير صحيح");
    }
  }

  return (
    <>
      {isError && isErrorVisible && (
        <Alert errorMessage={error.message}></Alert>
      )}
      <div className="flex flex-col gap-7">
        <p className="text-sm text-mobilawy-[#6D6E71]">
          ارسل طلبًا لحذف حسابك والمعلومات الشخصية المخزنة على نظامنا. بعد
          الإرسال، ستتلقى رسالة نصية للتحقق من طلبك، وبمجرد التحقق، سنقوم بحذف
          حسابك.
        </p>
        <InputMSISDN
          onChange={handleMsisdn}
          onBlur={validateMSISDN}
          ErrorMessage={MSISDNError}
        />
        <FormButton
          onClick={mutate}
          isDisabled={!isValidMsisdn || isPending}
          isLoading={isPending}
          btnText={"متابعة"}
        />
      </div>
    </>
  );
}
