const baseURL = "https://pricelessapp-api.dsquares.com/api/DynamicApp";

export const APIConfig = {
  VerifyOtp: {
    url: () => `${baseURL}/v1.0/Account/VerifyOtp`,
  },
  SendOtp: {
    url: () => `${baseURL}/v1.0/Account/SendOtp`,
  },
  DeleteAccount: {
    url: () => `${baseURL}/v1.0/Account/DeleteExternal`,
  },
};
