export const CALL_CENTER_PHONE = 15177;
export default function CallCenter({ onClick }) {
  function handlePhoneCall() {
    if (onClick) onClick();

    window.location.href = `tel:${CALL_CENTER_PHONE}`;
  }

  return (
    <div className="text-sm text-mobilawy-[#6D6E71] text-center">
      الإتصال بخدمة العملاء
      <a
        href={`tel:${CALL_CENTER_PHONE}`}
        onClick={handlePhoneCall}
        className="text-[#FF5F00] underline ms-1"
      >
        15177
      </a>
    </div>
  );
}
