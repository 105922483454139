const PricelessIcon = () => {
  return (
    <svg
      className="w-72"
      width="1210"
      height="198"
      viewBox="0 0 1210 198"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M370.771 29.3192C392.969 29.3192 414.678 27.8489 436.098 29.6796C464.912 32.13 480.264 57.86 470.952 85.19C464.754 103.367 450.152 110.257 432.609 111.727C419.982 112.779 407.211 112.232 394.497 112.39C391.931 112.419 389.351 112.39 386.05 112.39V168.953H370.771V29.3192ZM386.641 44.7572V96.0297C403.506 96.0297 419.896 97.2838 436.011 95.6549C449.907 94.2423 456.364 84.7864 456.566 71.2655C456.768 56.678 450.599 46.7608 436.299 45.1752C420.04 43.359 403.434 44.7572 386.656 44.7572H386.641Z"
        fill="white"
      />
      <path
        d="M805.931 126.819H730.255C731.437 138.639 735.833 148.037 745.852 153.371C763.192 162.596 779.221 158.243 794.385 146.553C796.879 149.666 799.214 152.592 802.083 156.167C800.18 157.854 798.566 159.511 796.735 160.881C767.055 182.921 725.282 169.76 716.59 135.655C709.628 108.311 723.192 81.6295 747.87 74.1772C781.038 64.1447 811.625 91.2152 805.931 126.834V126.819ZM730.702 114.336H791.315C789.874 97.2549 779.048 86.0836 763.639 85.3773C746.053 84.5557 733.844 95.0783 730.702 114.336Z"
        fill="white"
      />
      <path
        d="M963.425 126.545H887.576C888.181 137.933 892.39 146.87 901.414 152.362C919.187 163.158 935.835 158.416 951.619 146.61C954.113 149.782 956.463 152.751 959.302 156.34C956.823 158.373 954.762 160.29 952.484 161.875C922.877 182.459 882.617 169.227 873.867 136.073C867.15 110.617 878.494 84.945 900.52 75.7341C934.235 61.6511 967.259 87.6549 963.425 126.545ZM948.261 114.408C947.655 97.7451 936.167 85.8963 920.354 85.363C902.711 84.7576 890.026 96.0298 888.008 114.408H948.261Z"
        fill="white"
      />
      <path
        d="M980.665 158.574C981.63 156.816 982.582 154.928 983.677 153.14C984.657 151.555 985.825 150.07 987.18 148.138C998.985 157.075 1012.28 158.906 1026.23 157.839C1027.66 157.724 1029.11 157.623 1030.51 157.335C1040.93 155.201 1046.52 149.94 1046.34 142.488C1046.15 135.482 1040.44 130.826 1030.01 129.14C1021.03 127.684 1011.84 126.935 1003.11 124.585C990.193 121.097 983.951 113.039 983.721 101.796C983.461 89.1108 989.472 79.8855 1002.1 75.5899C1021.88 68.8728 1040.69 72.2313 1059.34 82.1341C1056.96 86.2999 1054.97 89.7594 1053.66 92.0513C1043.27 89.572 1033.91 86.4297 1024.31 85.3918C1018.46 84.7576 1011.6 86.1126 1006.42 88.8946C1002.79 90.8405 999.807 96.4478 999.115 100.83C998.236 106.365 1002.92 110.156 1008.18 111.324C1017.06 113.284 1026.16 114.207 1035.07 116.08C1045.85 118.358 1056.25 121.745 1060.39 133.594C1064.28 144.751 1060.07 156.383 1050.06 163.302C1032.67 175.324 996.939 173.003 980.636 158.546L980.665 158.574Z"
        fill="white"
      />
      <path
        d="M1077.13 158.444C1079.12 155.143 1081.16 151.756 1083.48 147.907C1095.59 157.104 1108.9 158.92 1122.85 157.853C1124.28 157.738 1125.73 157.637 1127.13 157.349C1137.55 155.216 1143.13 149.94 1142.93 142.487C1142.73 135.496 1137.02 130.841 1126.58 129.154C1117.6 127.698 1108.42 126.949 1099.69 124.585C1086.78 121.096 1080.54 113.024 1080.33 101.781C1080.07 89.096 1086.08 79.8419 1098.73 75.604C1118.5 68.9877 1137.34 72.058 1155.89 82.2779C1153.55 86.3428 1151.58 89.7879 1150.27 92.0654C1139.86 89.5861 1130.5 86.4582 1120.9 85.4059C1115.05 84.7717 1108.19 86.141 1103.02 88.923C1099.38 90.869 1096.41 96.4907 1095.74 100.873C1094.87 106.422 1099.56 110.184 1104.82 111.352C1113.7 113.298 1122.79 114.278 1131.7 116.094C1142.04 118.213 1151.94 121.558 1156.58 132.268C1161.25 143.035 1157.14 155.734 1147.24 162.913C1130.1 175.338 1093.67 173.19 1077.11 158.43L1077.13 158.444Z"
        fill="white"
      />
      <path
        d="M701.023 87.1214C698.169 90.1917 695.286 93.3052 692.446 96.3467C669.296 82.451 652.172 83.042 639.991 97.9034C628.763 111.612 629.44 134.041 641.476 146.956C654.32 160.736 672.655 160.65 692.36 146.48C695.214 149.565 698.097 152.693 700.907 155.735C686.738 174.661 652.72 176.391 633.245 159.54C612.402 141.507 611.249 105.572 630.896 85.8961C649.721 67.0275 683.869 67.4023 701.008 87.1069L701.023 87.1214Z"
        fill="white"
      />
      <path
        d="M846.119 169.155H832.166V24.7498H846.119V169.155Z"
        fill="white"
      />
      <path
        d="M557.742 75.0996C556.546 80.015 555.493 84.3538 554.282 89.3124C552.351 88.6781 550.881 88.1448 549.382 87.7123C528.293 81.615 512.913 92.5989 512.307 114.639C511.889 129.99 512.206 145.356 512.206 160.708C512.206 163.302 512.206 165.897 512.206 168.938H497.72V74.5519H511.5C511.803 78.1555 512.091 81.4276 512.48 86.1412C525.223 71.0635 540.344 69.6509 557.742 75.0852V75.0996Z"
        fill="white"
      />
      <path
        d="M576.856 168.924V74.6098H590.982V168.924H576.856Z"
        fill="white"
      />
      <path
        d="M584.553 50.1337C578.268 50.1337 574.275 46.1409 574.304 39.8706C574.333 33.6868 578.484 29.4921 584.538 29.5065C590.405 29.5065 594.902 34.0039 594.931 39.8706C594.96 45.8238 590.607 50.1193 584.553 50.1193V50.1337Z"
        fill="white"
      />
      <path
        d="M1182.4 68.2384V54.6023H1187.89V51.8203H1173.89V54.6023H1179.4V68.2384H1182.4ZM1209.58 68.2384V51.8059H1205.29L1200.36 63.1069L1195.41 51.8059H1191.12V68.2384H1194.14V55.8419L1198.77 66.5375H1201.91L1206.54 55.8131V68.2384H1209.57H1209.58Z"
        fill="white"
      />
      <path
        d="M203.188 21.1313H116.686V176.563H203.188V21.1313Z"
        fill="#FF5F00"
      />
      <path
        d="M122.178 98.8549C122.178 67.3159 136.938 39.2364 159.929 21.1317C143.122 7.89917 121.904 0 98.8405 0C44.2527 0 0 44.2527 0 98.8549C0 153.457 44.2527 197.71 98.8405 197.71C121.904 197.71 143.122 189.811 159.929 176.578C136.938 158.473 122.178 130.394 122.178 98.8549Z"
        fill="#EB001B"
      />
      <path
        d="M319.873 98.8549C319.873 153.443 275.621 197.71 221.033 197.71C197.97 197.71 176.751 189.811 159.944 176.578C182.935 158.473 197.696 130.394 197.696 98.8549C197.696 67.3159 182.935 39.2364 159.944 21.1317C176.751 7.89917 197.97 0 221.018 0C275.606 0 319.859 44.2527 319.859 98.8549H319.873Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default PricelessIcon;
