import { useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { deleteAccount } from "../../../../services/authentication";

import { useAuthentication } from "../../../../context/authentication-context";

import Alert from "../../../../components/Alert";

export default function Deletion({ moveToNextStep, moveToSpecificStep }) {
  const { token, mobileNumber } = useAuthentication();

  const [isErrorVisible, setIsErrorVisible] = useState(true);

  const { mutate, isPending, isError, error } = useMutation({
    mutationFn: () =>
      deleteAccount({
        data: {
          mobileNumber,
          token,
        },
      }),
    onSuccess: () => moveToNextStep(),
    onError: () => setIsErrorVisible(true),
  });

  return (
    <div className="flex flex-col justify-center items-center text-center gap-7">
      <div className="flex flex-col gap-3">
        <span className="text-sm">سيتم حذف حسابك نهائيا</span>
      </div>

      {/* Error Alert : START */}
      {isError && isErrorVisible && (
        <Alert errorMessage={error.message}></Alert>
      )}
      {/* Error Alert  :  END */}

      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="h-16 w-16"
      >
        <path
          d="M21 5.25H17.441C16.54 5.25 16.502 5.136 16.255 4.396L16.053 3.789C15.746 2.869 14.889 2.25 13.919 2.25H10.081C9.11099 2.25 8.253 2.868 7.947 3.789L7.745 4.396C7.498 5.137 7.46 5.25 6.559 5.25H3C2.586 5.25 2.25 5.586 2.25 6C2.25 6.414 2.586 6.75 3 6.75H4.298L5.065 18.249C5.213 20.474 6.57701 21.75 8.80701 21.75H15.194C17.423 21.75 18.787 20.474 18.936 18.249L19.703 6.75H21C21.414 6.75 21.75 6.414 21.75 6C21.75 5.586 21.414 5.25 21 5.25ZM9.37 4.263C9.473 3.956 9.75799 3.75 10.081 3.75H13.919C14.242 3.75 14.528 3.956 14.63 4.263L14.832 4.87C14.876 5.001 14.92 5.128 14.968 5.25H9.03C9.078 5.127 9.12301 5 9.16701 4.87L9.37 4.263ZM17.438 18.149C17.343 19.582 16.629 20.25 15.193 20.25H8.806C7.37 20.25 6.657 19.583 6.561 18.149L5.801 6.75H6.558C6.683 6.75 6.787 6.737 6.899 6.729C6.933 6.734 6.964 6.75 6.999 6.75H16.999C17.035 6.75 17.065 6.734 17.099 6.729C17.211 6.737 17.315 6.75 17.44 6.75H18.197L17.438 18.149ZM14.75 11V16C14.75 16.414 14.414 16.75 14 16.75C13.586 16.75 13.25 16.414 13.25 16V11C13.25 10.586 13.586 10.25 14 10.25C14.414 10.25 14.75 10.586 14.75 11ZM10.75 11V16C10.75 16.414 10.414 16.75 10 16.75C9.586 16.75 9.25 16.414 9.25 16V11C9.25 10.586 9.586 10.25 10 10.25C10.414 10.25 10.75 10.586 10.75 11Z"
          fill="#B1232D"
        />
      </svg>

      <div className="flex gap-4 w-full mt-4">
        <button
          onClick={mutate}
          disabled={isPending}
          className="text-white bg-mobilawy-[#BF3128] hover:bg-[#bf3228e6] w-1/2 text-center py-3 rounded-lg"
        >
          حذف الحساب
        </button>
        <button
          onClick={() => moveToSpecificStep(0)}
          className="text-mobilawy-[#BF3128] hover:bg-[#bf32281d] ring-1 ring-mobilawy-[#BF3128] w-1/2 text-center py-3  rounded-lg"
        >
          إلغاء
        </button>
      </div>
    </div>
  );
}
